<template>
  <div>

    <el-dialog title="唤醒机会库" :visible.sync="showWakeBankObj.isShow" width="68%">
      <el-table :data="wakeOpportunityData" v-loading="loading">
        <el-table-column align="center" property="telephone" label="机会号码" width="120"></el-table-column>
        <el-table-column align="center" label="唤醒时间" width="160">
          <template slot-scope="slot">
            {{ formatter(slot.row.wakeTime) }}
          </template>
        </el-table-column>
        <el-table-column align="center" property="createTime" label="首次上传时间" width="160">
          <template slot-scope="slot">
            {{ formatter(slot.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column align="center" property="mediatorId" label="来源" width="100">
          <template v-slot="scope">
            <span v-for="item in mediatorList" :key="item.id" v-if="item.id === scope.row.mediatorId">{{ item.mediatorName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="支付状态" width="100">
          <template slot-scope="slot">
            <span v-if="slot.row.orderStatus === 0">未成单</span>
            <span v-if="slot.row.orderStatus === 1">已成单</span>
            <span v-if="slot.row.orderStatus === 2">已下单</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="slot">
            <el-button size="mini" @click="showHistory(slot.row.id)">历史记录</el-button>
            <el-button size="mini" @click="drawWakeOpportunity(slot.row.id)">领取</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" justify="end">
        <el-col :span="3" :pull="2">
          <el-button type="primary" size="mini" @click="initWakeData">换一换</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog v-if="isShowOperation" :visible.sync="isShowOperation">
      <History :id="chooseId"></History>
    </el-dialog>

  </div>
</template>

<script>
import {drawWakeOpportunity, wakeOpportunityList} from "../../../utils/api";
import History from "../../../components/details/History";
import * as mediatorApi from '@/api/crm/mediatorCut'

export default {
  name: "WakeBank",
  components: {History},
  props: {
    showWakeBankObj: {
      type: Object,
      require: true
    },
    pondId:{
        type:Number,
        default:1
    }
  },
  data() {
    return {
      mediatorList: [],
      dialogTableVisible: true,
      wakeOpportunityData: [],
      loading: true,
      isShowOperation: false,
      chooseId: 0
    };
  },
  methods: {
    findMediator() {
      mediatorApi.listMediator().then(res => {
        this.mediatorList = res.data.data
      })
    },
    initWakeData: function () {
      wakeOpportunityList(5,this.pondId).then(res => {
        if (res.success) {
          this.wakeOpportunityData = res.data
          this.loading = false
        } else {
          this.$message.warning(res.msg)
        }
      })
    }, formatter: function (data) {
      if (data) {
        return data.replace("T", " ");
      }
      return "";
    }, showHistory: function (id) {
      this.chooseId = id
      this.isShowOperation = true
    }, drawWakeOpportunity(opportunityId) {
      drawWakeOpportunity(opportunityId).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.showWakeBankObj.isShow = false
          this.$emit("reload")
        } else {
          this.$message.warning(res.msg)
        }
      }).catch(error => {
        this.$message.error("领取唤醒机会失败")
      })
    }
  },
  mounted() {
    this.findMediator()
    this.initWakeData()
  }
}
</script>

<style scoped>

</style>
