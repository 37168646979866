<template>
  <el-row>
    <el-dialog title="导入批量回库" v-if="showObj.isShow" :visible.sync="showObj.isShow" width="60%">
      <el-upload
          class="upload-demo"
          ref="upload"
          :action="url"
          :headers="headers"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :file-list="fileList"
          :on-success="handleSuccess"
          :on-error="handleError"
          :before-upload="handleBefore"
          :auto-upload="false">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>

        <div slot="tip" class="el-upload__tip">只能上传.xls或者.xlsx文件，且不超过7M大小</div>
        <div slot="tip" class="el-upload__tip" style="font-weight: bold;color: #f56c6c">
          注意：使用Excel导入时请注意，必须严格填写字段、项目字段在系统中真实存在，否则无法录入
        </div>
        <div slot="tip" class="el-upload__tip" v-if="isShowResult">{{ msg }}</div>
        <div slot="tip" class="el-upload__tip" v-if="isShowResult" v-for="(resMsg,index) in msgArr" :key="index">
          {{ resMsg }}
        </div>
      </el-upload>
    </el-dialog>
  </el-row>
</template>

<script>
export default {
  name: "OpportunityListBack",
  props: {
    showObj: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
        headers:{"version": localStorage.getItem("_version"),},
      fileList: [],
      msgArr: [],
      totalCount: 0,
      successCount: 0,
      isShowResult: false,
      url: '/api/manage/crm/counselor/opportunityListBack',
    }
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    /**
     * 文件上传前检查
     */
    handleBefore(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 7
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是 xls、xlsx格式!',
          type: 'warning'
        });
      }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 7MB!',
          type: 'warning'
        });
      }
      return extension || extension2 && isLt2M
    },
    /**
     * 上传成功的回调方法
     * @param res
     * @param file
     * @param fileList
     */
    handleSuccess(res, file, fileList) {
      if (res.success) {
        this.$message.success(res.msg)
        // this.msgArr = res.data.msgList
        // this.successCount = res.data.successCount
        // this.totalCount = res.data.totalCount
        this.isShowResult = true
        this.$emit('getList')
      } else {
        this.$message.warning(res.msg)
      }
    },
    /**
     * 文件上传失败
     * @param err
     * @param file
     * @param fileList
     */
    handleError(err, file, fileList) {
      this.$message.error("文件上传失败，请联系管理员")
    }
  },
  watch: {
    "showObj.isShow": function (newVal, oldVal) {
      if (newVal) {
        this.msgArr = []
        this.msg = ""
        this.isShowResult = false
      }
    }
  },
  computed: {
    msg: {
      get: function () {
        return `导入批量回库完成。一共${this.totalCount}条，成功回库：${this.successCount}条，回库失败：${this.totalCount - this.successCount}条`
      },
      set: function (newValue) {
        return newValue
      }
    },
  }
}
</script>

<style scoped>

</style>
