<template>
    <div v-loading="loading">

        <el-alert
                v-if="showSummary"
                :closable="false"
                title="粗略合计学习时长"
                type="success"
                :description="`${summary}`">
        </el-alert>

        <el-alert
                v-if="showError && learningRecordPage.source === 1"
                :closable="false"
                title="错误"
                type="error"
                description="无用户学习记录">
        </el-alert>

        <div style="display: flex;flex-direction: row;justify-content: space-between; padding: 0 10px; margin-top: 20px">
            <el-radio-group v-model="learningRecordPage.source" size="medium" @change="findList()">
<!--                <el-radio-button :label="1">小鹅通</el-radio-button>-->
                <el-radio-button :label="2">三格</el-radio-button>
            </el-radio-group>

            <el-select v-if="learningRecordPage.source === 2" v-model="learningRecordPage.goodsId" placeholder="请选择课程" @change="findList()" clearable>
                <el-option v-for="item in goodsList" :key="item.goodsId" :value="item.goodsId" :label="item.goodsName"/>
            </el-select>
        </div>

        <el-table
                v-if="showTable"
                :data="learningRecord"
                stripe
                style="width: 100%">
            <el-table-column align="left" prop="title" label="学习内容" width="250"/>
            <el-table-column align="center" prop="resourceType" label="类型" width="100"/>
            <el-table-column align="center" prop="stayTime" label="学习时长">
                <template v-slot="scope">
                    {{ formatTime(scope.row.stayTime) }}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="isFinish" label="是否学完">
                <template v-slot="scope">
                    <el-tag
                            :type="scope.row.isFinish === 1 ? 'success' : 'danger'"
                            effect="plain">
                        {{ scope.row.isFinish === 1 ? '是' : '否' }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="state" label="资源状态">
                <template v-slot="scope">
                    <!--          资源状态 0-正常 1-隐藏 2-删除-->
                    <el-tag v-if="scope.row.state === 0" type="success">正常</el-tag>
                    <el-tag v-else-if="scope.row.state === 1" type="info">隐藏</el-tag>
                    <el-tag v-else-if="scope.row.state === 2" type="danger">删除</el-tag>
                    <el-tag v-else type="danger">异常</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="lastLearnTime" label="最近学习时间"/>
        </el-table>
        <el-pagination
                v-if="showTable"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="learningRecordPage.current"
                :page-size="learningRecordPage.size"
                layout="total,  prev, pager, next, jumper"
                :total="learningRecordPage.total">
        </el-pagination>
    </div>
</template>

<script>
import * as api from "@/utils/api";


export default {
    name: "LearnDetail",
    props: {
        opportunityId: {
            type: Number,
            require: true,
            default: '',
        }
    },
    data() {
        return {
            learningRecord: [],
            learningRecordPage: {
                current: 1,
                size: 50,
                total: 0,
                opportunityId: '',
                source: 2,
                goodsId: '',
            },
            showSummary: false,
            showError: false,
            showTable: false,
            summary: 0,
            loading: true,
            goodsList: [],
        }
    },
    computed: {
        // summary() {
        //     const sum = eval(this.learningRecord.map(e => e.stayTime).join('+'))
        //     let d = this.learningRecordPage.size;
        //     if (this.learningRecordPage.size > this.learningRecordPage.total) {
        //         d = this.learningRecordPage.total
        //     }
        //     return this.formatTime(sum / d * this.learningRecordPage.total);
        // },
    },
    methods: {
        formatTime(time) {
            const hour = Math.trunc(time / 3600);
            const hourStr = hour > 9 ? hour : "0" + hour
            const min = Math.trunc((time - (hour * 3600)) / 60)
            const minStr = min > 9 ? min : "0" + min
            const sec = Math.trunc(time - (min * 60 + hour * 3600))
            const secStr = sec > 9 ? sec : "0" + sec
            return hourStr + ":" + minStr + ":" + secStr
            // return (hour > 0 ? hour + '小时' : '') + (min > 0 ? min + '分钟' : '') + sec + '秒'
        },
        findList() {
            this.learningRecordPage.opportunityId = this.opportunityId;
            if (this.learningRecordPage.source === 2 && !this.learningRecordPage.goodsId) {
                this.loading = false
                return
            }
            api.getLearningRecord(this.learningRecordPage).then(resp => {
                if (resp.success) {
                    this.learningRecord = resp.data.data
                    this.learningRecordPage.total = resp.data.count
                    this.showSummary = true
                    this.showError = false
                    this.showTable = true
                    this.loading = false
                    this.summary = this.formatTime(resp.data.tBo)
                } else {
                    this.showTable = false
                    this.showError = true
                    this.loading = false
                    this.summary = this.formatTime(0)
                    this.$message.error("无用户学习记录")
                    // this.$message.warning(resp.msg)
                }
            })
        },
        getPurchasedGoods() {
            if (this.learningRecordPage.source === 2) {
                api.getPurchasedGoods({opportunityId: this.opportunityId, source: this.learningRecordPage.source}).then(resp => {
                    if (resp.success) {
                        this.goodsList = resp.data
                        if (this.goodsList.length > 0) {
                            this.learningRecordPage.goodsId = this.goodsList[0].goodsId
                            this.findList();
                        }
                    } else {
                        this.$message.error(resp.msg)
                    }
                })
            }
        },
        handleSizeChange(val) {
            this.learningRecordPage.size = val
            this.findList()
        },
        handleCurrentChange(val) {
            this.learningRecordPage.current = val
            this.findList()
        },
    },

    mounted() {
        this.findList();
        this.getPurchasedGoods();
    }
}
</script>

<style scoped>

</style>
