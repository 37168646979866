<template>
    <div>
        <el-dialog
                title="创建优惠券链接"
                :visible.sync="createCouponShareDialogVisible"
                width="40%"
                :before-close="createCoupon">

            <el-form :model="form">
                <el-form-item label="优惠券类型" label-width="200px">
                    <el-select v-model="form.batch" value-key="batchId" placeholder="请选择优惠券种类">
                        <el-option-group
                                v-for="(group,index) in userBatchList"
                                :key="index"
                                :label="group.label">
                            <el-option
                                    v-for="item in group.options"
                                    :key="item.batchId"
                                    :label="item.name"
                                    :value="item">
                            </el-option>
                        </el-option-group>
                    </el-select>
                </el-form-item>

                <el-form-item v-if="form.batch.batchId" label="详情" label-width="200px">
                    <div v-if="form.batch.type === 0">
                        折扣券: 打 {{ form.batch.discount / 10 }} 折
                    </div>
                    <div v-if="form.batch.type === 1">
                        满减券: 满 {{ (form.batch.fullAmount / 100).toFixed(2) }} 元，减
                        {{ (form.batch.discount / 100).toFixed(2) }} 元
                    </div>
                    <div v-if="form.batch.type === 2">
                        代金券: 直接抵扣 {{ (form.batch.discount / 100).toFixed(2) }} 元
                    </div>
                </el-form-item>

                <el-form-item label="本链接可领取数量" label-width="200px">
                    <el-input-number v-model="form.grantNum" :step="1" :max="form.batch.count"></el-input-number>
                </el-form-item>

                <el-form-item label="链接生效时间至" label-width="200px">
                    <el-date-picker
                            v-model="form.endTime"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择截止时间">
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-button :disabled="!(form.batch && form.grantNum > 0)" @click="generateUrl">点击生成分享链接
                    </el-button>
                </el-form-item>

                <el-form-item>
                    <el-button :disabled="!form.url" @click="copyUrl">点击复制</el-button>
                    <el-link type="success">{{ form.url }}</el-link>
                </el-form-item>

                <el-form-item>
                    <el-button :disabled="!form.url" @click="downImage">点击保存分享图</el-button>
                    <div style="display: flex;justify-content: center">
                        <CouponShareUrlImage v-if="form.url" :src="form.url" :img-params="imgParams"
                                             ref="couponQrcode"></CouponShareUrlImage>
                    </div>
                </el-form-item>
            </el-form>

            <el-dialog title="历史分享链接" :visible.sync="dialogTableVisible" append-to-body>
                <el-select v-model="queryUserBatchHistoryParams.type" placeholder="请选择">
                    <el-option label="正常" :value="1"/>
                    <el-option label="已过期" :value="2"/>
                    <el-option label="全部" :value="3"/>
                </el-select>
                <el-button style="margin-left: 2%" type="primary" @click="queryHistoryCouponUrl">查询</el-button>
                <el-link style="margin-left: 5%" v-if="queryUserBatchHistoryParams.type === 1" type="warning">
                    查询正常链接，可能会有20分钟的误差过期更新
                </el-link>
                <el-table :data="historyCouponUrlList">
                    <el-table-column property="adviserName" label="姓名" width="80" align="center"/>
                    <el-table-column property="url" label="链接" width="200" align="center"/>
                    <el-table-column property="createTime" label="链接创建时间" width="110" align="center"/>
                    <el-table-column property="startTime" label="开始时间" width="100" align="center"/>
                    <el-table-column property="endTime" label="截止时间" width="100" align="center"/>
                    <el-table-column property="totalCount" label="总数" width="50" align="center"/>
                    <el-table-column property="receivedCount" label="领取数量" width="80" align="center"/>
                    <el-table-column property="coupon" label="详情" align="center">
                        <template v-slot="scope">
                            <div v-if="scope.row.coupon">
                                名称: {{ scope.row.coupon.name }}
                                <div v-if="scope.row.coupon.type === 0">
                                    折扣券: 打 {{ scope.row.coupon.discount / 10 }} 折
                                </div>
                                <div v-if="scope.row.coupon.type === 1">
                                    满减券: 满 {{ (scope.row.coupon.fullAmount / 100).toFixed(2) }} 元，减
                                    {{ (scope.row.coupon.discount / 100).toFixed(2) }} 元
                                </div>
                                <div v-if="scope.row.coupon.type === 2">
                                    代金券: 直接抵扣 {{ (scope.row.coupon.discount / 100).toFixed(2) }} 元
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column property="deleted" label="状态" align="center">
                        <template v-slot="scope">
                            <el-tag type="success" v-if="scope.row.deleted === 0">正常</el-tag>
                            <el-tag type="danger" v-else>已删除/已过期</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>

            <span slot="footer" class="dialog-footer">
    <el-button @click="queryHistoryCouponUrl">查看历史链接</el-button>
    <el-button @click="createCoupon">关 闭</el-button>
    <el-button type="primary" @click="createCoupon">完 成</el-button>
  </span>
        </el-dialog>

    </div>
</template>

<script>
import {generateCouponUrl, getUserBatch, queryUserBatchHistoryList} from "@/api/crm/crmBatch";
import {copy} from "@/utils/utils";
import CouponShareUrlImage from "@/components/details/CouponShareUrlImage.vue"

export default {
    name: "CreateCouponShare",
    components: {CouponShareUrlImage},
    props: {
        createCouponShareDialogVisible: {
            type: Boolean,
            require: true,
            default: false
        }
    },
    data() {
        return {
            form: {
                batch: {
                    count: 0
                },
                grantNum: 0,
                url: null,
                endTime: null
            },
            userBatchList: [],
            imgParams: {
                url: null,
                adviserName: null,
                couponName: null,
                description: null,
                endTime: null,
                couponType: null,
                discount: null
            },
            dialogTableVisible: false,
            historyCouponUrlList: [],
            queryUserBatchHistoryParams: {
                type: 1
            }
        }
    },
    methods: {
        downImage() {
            if (!this.form.url) {
                return
            }
            this.$refs.couponQrcode.domToImg()
        },
        copyUrl() {
            if (this.form.url) {
                copy(this.form.url)
                this.$message.success('复制成功!');
            }
        },
        generateUrl() {
            const params = {}
            params.batchId = this.form.batch.batchId;
            params.grantNum = this.form.grantNum;
            params.endTime = this.form.endTime
            generateCouponUrl(params).then(resp => {
                if (resp.success) {
                    this.form.url = resp.data.url
                    this.imgParams = resp.data
                } else {
                    this.$message.error(resp.msg)
                }
            })
        },
        getUserBatch() {
            this.userBatchList = []
            getUserBatch().then(resp => {
                if (resp.success) {
                    const list0 = resp.data['0'] ? resp.data['0'] : []
                    const list1 = resp.data['1'] ? resp.data['1'] : []
                    const list2 = resp.data['2'] ? resp.data['2'] : []
                    this.userBatchList.push({label: "折扣券", options: list0})
                    this.userBatchList.push({label: "满减券", options: list1})
                    this.userBatchList.push({label: "代金券", options: list2})
                }
            })
        },
        createCoupon() {
            this.$emit("createCoupon", false)
        },
        goBack() {
            //跳转到上一次浏览的页面
            this.$router.go(-1)
        },
        queryHistoryCouponUrl() {
            queryUserBatchHistoryList(this.queryUserBatchHistoryParams).then(resp => {
                if (resp.success) {
                    this.historyCouponUrlList = resp.data
                    this.dialogTableVisible = true;
                } else {
                    this.$message.error(resp.msg)
                }
            })
        }
    },
    mounted() {
        this.getUserBatch();
    }
}
</script>

<style scoped>

</style>
