<template>
    <div id="__qrcode" class="qrcode-body">
        <div class="top">
            <span>证儿八经</span>
        </div>
        <div class="qr-img">
            <div id="qrcode"></div>
        </div>

        <div class="info-body">
            <div style="height: 40px"></div>
            <h1>证儿八经{{ imgParams.adviserName }}老师送了您一张优惠券!</h1>
            <h1>扫码领取</h1>
        </div>

        <div class="coupon-body">
            <div class="left">
                <span class="title">{{ imgParams.couponName }}</span>
                <span class="time">有效期至：{{ imgParams.endTime }}</span>
            </div>

            <div class="right">
                <div v-if="imgParams.couponType === 0">
                    <div class="money">{{ (imgParams.discount / 10) }} 折</div>
                    <div class="term">满{{ (imgParams.fullAmount / 100).toFixed(2) }}元可用</div>
                </div>
                <div v-else-if="imgParams.couponType === 1">
                    <div class="money">¥ {{ (imgParams.discount / 100).toFixed(2) }}</div>
                    <div class="term">满{{ (imgParams.fullAmount / 100).toFixed(2) }}元可用</div>
                </div>
                <div v-else-if="imgParams.couponType === 2">
                    <div class="money">¥ {{(imgParams.discount / 100).toFixed(2) }} 元</div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {endianness} from "os";

export default {
    name: 'CouponShareUrlImage',
    components: {},
    data() {
        return {
            imgCanvas: '',
            qrcode: null
        }
    },
    props: {
        /*
        * 小程序链接的地址
        * */
        src: {
            type: String,
            default: '',
            required: true
        },
        imgParams: {
            type: Object,
            default: {
                url: null,
                adviserName: null,
                couponName: null,
                description: null,
                endTime: null,
                couponType: null,
                discount: null,
                fullAmount: null
            },
            require: true
        }
    },
    computed: {},
    watch: {
        src: {
            handler: function (val, oldVal) {
                // console.log(val)
                this.createQrImg()
            },
            immediate: true
        }
    },
    mounted() {
    },
    methods: {
        endianness,
        /*
        * 链接转成二维码
        * */
        async createQrImg() {
            await this.addScript('https://zebj-app.oss-cn-beijing.aliyuncs.com/2023/11/24/acbec3758a584c55889202da943a6bb0.js')

            if (this.qrcode) {
                this.qrcode.clear()
            }

            this.qrcode = new window.QRCode(document.getElementById("qrcode"), {
                text: this.src,
                width: 100,
                height: 100,
                colorDark: "#000000",
                colorLight: "#ffffff"
            });
        },
        downloadImg() {
            // console.log(this.imgCanvas)
            if (this.imgCanvas) {
                const btn = document.createElement('a')
                btn.href = this.imgCanvas.toDataURL('image/png')
                btn.download = 'qrcode.png'
                btn.click()
            }
        },
        imgToBase64(url) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'anonymous';
                img.onload = () => {
                    const canvas = document.createElement('canvas')
                    const ctx = canvas.getContext('2d')
                    canvas.width = img.width
                    canvas.height = img.height
                    ctx.drawImage(img, 0, 0, img.width, img.height)
                    const dataURL = canvas.toDataURL('image/png')
                    console.log(dataURL)
                    resolve(dataURL)
                }
            })

        },
        async domToImg() {
            await this.addScript('https://zebj-app.oss-cn-beijing.aliyuncs.com/2023/11/24/1d602448be3e4767b81b00970f1e7720.js')
            // console.log('加载完成')
            window.html2canvas(document.getElementById("__qrcode"), {
                useCORS: true,
                backgroundColor: '#ffffff'
            }).then(canvas => {
                this.imgCanvas = canvas
                // document.body.appendChild(canvas)
                this.downloadImg()
            });

        },
        /*
        * 动态加载js
        * */
        addScript(url) {
            return new Promise(function (resolve, reject) {
                const scriptInfo = document.createElement('script')
                scriptInfo.type = 'text/javascript'
                scriptInfo.src = url
                document.body.appendChild(scriptInfo)
                scriptInfo.onload = scriptInfo.onreadystatechange = function () {
                    if (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') {
                        scriptInfo.onload = scriptInfo.onreadystatechange = null
                        resolve()
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.qrcode-body {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    width: 310px;
    background-color: #ffffff;
}

.top {
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
    padding: 10px 20px;
    height: 100px;
}

.qr-img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;
    text-align: center;
    overflow: hidden;
    width: 100%;
    position: absolute;
    top: 110px;
    left: 0;
    right: 0;
    z-index: 2;
}

.qr-img img {
    object-fit: cover;
}

.info-body {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    background-color: #FFFFFF;
    position: relative;
}

.info-body h1 {
    font-size: 18px;
    text-align: center;
}

.coupon-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    overflow: hidden;
    height: 130px;
    box-sizing: border-box;
    background-color: #FFFFFF;
}

.coupon-body .left {
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    word-break: break-all;
    width: 160px;
    box-sizing: border-box;
    height: 100%;
}

.coupon-body .left .title {
    font-size: 16px;
}

.coupon-body .left .msg {
    font-size: 12px;
    margin: 10px 0;
}

.coupon-body .left .time {
    font-size: 12px;
}

.coupon-body .right {
    height: 100%;
    background-color: blue;
    border-radius: 5px;
    padding: 8px;
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.coupon-body .right .money {
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
}

.coupon-body .right .term {
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 10px;
}

.coupon-body .right .btn {
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 8px;
    font-size: 12px;
    color: #333333;
    text-align: center;
    cursor: pointer;
}
</style>
