<template>
  <div class="container">
    <div :style="{height:collapseHeight}" class="transition-box">
      <el-form :inline="true" label-width="100px" label-position="right" size="small">
        <template v-for="(d,index) in data">
          <el-form-item :label="d.label" size="mini">
            <template #label>
              <el-tooltip class="item" effect="dark" :content="d.label" placement="top-start">
                <div style="width:100%;white-space:nowrap;overflow:hidden">{{ d.label }}</div>
              </el-tooltip>
            </template>
            <el-input v-model="form[d.name]" v-if="d.type === 'INPUT'" class="condition-input"></el-input>
            <el-select v-if="d.type === 'SELECT'" v-model="form[d.name]" class="condition-select" clearable
                       placeholder="请选择">
              <el-option v-for="item in d.options" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-date-picker v-if="d.type === 'DATETIME'" v-model="form[d.name]" size="mini" type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期" :default-time="['00:00:00', '23:59:59']"
                            end-placeholder="结束日期">
            </el-date-picker>
            <el-select v-if="d.type === 'SUBJECT'" v-model="form[d.name]" clearable class="condition-select"
                       placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="item in subjectList" :key="item.id" :label="item.subjectName" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-if="d.type === 'POSSESS'" v-model="form[d.name]" class="condition-select" placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="item in possessList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-if="d.type === 'MEDIATOR'" v-model="form['mediatorId']" class="condition-select"
                       placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="item in mediatorList" :key="item.id" :label="item.mediatorName" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-if="d.type === 'AREA'" v-model="form[d.name]" class="condition-select" placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="item in areaList" :key="item.id" :label="item.areaName" :value="item.areaName">
              </el-option>
            </el-select>
            <el-input v-if="d.type === 'input'" v-model="e[d.id]" class="condition-input"></el-input>
            <el-select v-if="d.type === 'radio'" v-model="e[d.id]"
                       clearable
                       class="condition-select"
                       placeholder="请选择">
              <el-option v-for="item in d.options" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-if="d.type === 'checkbox'" v-model="e[d.id]"
                       clearable
                       class="condition-select"
                       placeholder="请选择">
              <el-option v-for="item in d.options" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="e[d.id]" v-if="d.type === 'DICT'"
                       class="condition-select" clearable
                       placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option label="未指定" value="ISNULL"></el-option>
              <el-option v-if="extraMap[d.name]" v-for="item in extraMap[d.name]" :key="item.id"
                         :label="item.dictLabel" :value="item.id">
              </el-option>
            </el-select>

            <el-select v-if="d.type === 'TAGS'" multiple filterable v-model="form['tagList']">
              <el-option v-for="item in tagsOptions" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>


        </template>
        <!-- 额外信息 start -->
        <!--        <template v-for="(d,index) in SearchNewInformationList" v-if="JSON.parse(d.data).queryDisplay === true">-->
        <!--          <template v-if="d.type === 'input'">-->
        <!--            <el-form-item :label="d.label" size="mini">-->
        <!--              <el-input v-model="e[d.id]" class="condition-input"></el-input>-->
        <!--            </el-form-item>-->
        <!--          </template>-->
        <!--          <template v-if="d.type === 'radio'">-->
        <!--            <el-form-item :label="d.label" size="mini">-->
        <!--              <el-select v-model="e[d.id]"-->
        <!--                         class="condition-select"-->
        <!--                         placeholder="请选择">-->
        <!--                <el-option v-if="d.tson.dictList" v-for="item in d.tson.dictList" :key="item.id"-->
        <!--                           :label="item.value" :value="item.value">-->
        <!--                </el-option>-->
        <!--              </el-select>-->
        <!--            </el-form-item>-->
        <!--          </template>-->
        <!--          <template v-if="d.type === 'checkbox'">-->
        <!--            <el-form-item :label="d.label" size="mini">-->
        <!--              <el-select v-model="e[d.id]"-->
        <!--                         class="condition-select"-->
        <!--                         placeholder="请选择">-->
        <!--                <el-option v-if="d.tson.dictList" v-for="item in d.tson.dictList" :key="item.id"-->
        <!--                           :label="item.value" :value="item.value">-->
        <!--                </el-option>-->
        <!--              </el-select>-->
        <!--            </el-form-item>-->
        <!--          </template>-->
        <!--        </template>-->
        <!-- 额外信息 end -->

      </el-form>
    </div>
    <div class="button-container">
      <el-button size="mini" :icon="textIcon" type="text" @click="showMore">{{ text }}</el-button>
      <el-button type="primary" size="mini" @click="doSearch">查询</el-button>
      <el-button type="info" size="mini" @click="reset">重置</el-button>
    </div>
  </div>
</template>

<script>
import * as api from "../utils/api";
import * as tagsApi from "@/api/crm/tags";
import * as dictApi from "@/api/system/dictionary";

export default {
  name: "GgdCondition",
  props: {
    subjectList: {
      type: Array,
      require: false,
      default: () => {
        return []
      }
    },
    possessList: {
      type: Array,
      require: false,
      default: () => {
        return []
      }
    },
    data: {
      type: Array,
      require: false,
      default: () => {
        return []
      }
    },
    opporTypeRadios: {
      type: String,
      require: true,
    },
    // SearchNewInformationList: {},
  },
  data: () => ({
    isShow: false,
    text: '展示搜索条件',
    textIcon: 'el-icon-caret-bottom',
    collapseHeight: '85px',
    form: {
      telephone: ''
    },
    input: '',
    mediatorList: [],
    areaList: [],
    dictionaryList: [],
    tagsOptions: [],
    e: {},
    extraMap: {
      "BHBIIGGHFE": [],
      "BHBIGCFBGB": [],
      "BHBIGCFCIH": [],
    },
  }),
  created() {
    // window.addEventListener('keydown', this.keyboard, true)//开启监听键盘按下事件
    this.init();
    this.findExtraMap()
    this.findTagsOptions()
  },
  methods: {
    /**
     * 组装额外参数字典
     */
    findExtraMap() {
        dictApi.findExtraDictMap().then(res=>{
            if(res.success){
                this.extraMap = res.data
            }
        })
    },
    findTagsOptions() {
      tagsApi.options().then(res => {
        this.tagsOptions = res.data
      })
    },
    keyboard(e) {
      if (e.keyCode === 13) {
        this.doSearch()
      }
    },
    init() {
      // const data = require("@/assets/meta.json");
      // this.data = data.choose;
    },
    showMore() {
      this.isShow = !this.isShow
      this.textIcon = this.isShow ? 'el-icon-caret-top' : 'el-icon-caret-bottom'
      this.text = this.isShow ? '收起搜索条件' : '展示搜索条件'
      this.collapseHeight = this.isShow ? 'auto' : '85px'
    },
    reset() {
      this.form = {}
      this.$emit('doSearch', this.form)
    },
    getAreaList: function () {
      api.areaListByType(0).then(res => {
        if (res.success) {
          this.areaList = res.data
        }
      })
    },
    dueConditionTime: function () {
      if (this.form.vallotTimeCondition) {
        let vallotTime = this.form.vallotTimeCondition
        let vallotTimeStart = vallotTime[0].format("yyyy-MM-dd HH:mm:ss")
        let vallotTimeEnd = vallotTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.form.vallotTimeStart = vallotTimeStart
        this.form.vallotTimeEnd = vallotTimeEnd
      } else {
        this.form.vallotTimeStart = ""
        this.form.vallotTimeEnd = ""
      }

      if (this.form.uploadTimeCondition) {
        let uploadTime = this.form.uploadTimeCondition
        let uploadTimeStart = uploadTime[0].format("yyyy-MM-dd HH:mm:ss")
        let uploadTimeEnd = uploadTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.form.uploadTimeStart = uploadTimeStart
        this.form.uploadTimeEnd = uploadTimeEnd
      } else {
        this.form.uploadTimeStart = ""
        this.form.uploadTimeEnd = ""
      }

      if (this.form.executeTimeCondition) {
        let executeTime = this.form.executeTimeCondition
        let executeTimeStart = executeTime[0].format("yyyy-MM-dd HH:mm:ss")
        let executeTimeEnd = executeTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.form.executeTimeStart = executeTimeStart
        this.form.executeTimeEnd = executeTimeEnd
      } else {
        this.form.executeTimeStart = ""
        this.form.executeTimeEnd = ""
      }


      if (this.form.lastTimeCondition) {
        let lastTime = this.form.lastTimeCondition
        let lastTimeStart = lastTime[0].format("yyyy-MM-dd HH:mm:ss")
        let lastTimeEnd = lastTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.form.lastTimeStart = lastTimeStart
        this.form.lastTimeEnd = lastTimeEnd
      } else {
        this.form.lastTimeStart = ""
        this.form.lastTimeEnd = ""
      }

      if (this.form.createTimeCondition) {
        let createTime = this.form.createTimeCondition
        let createTimeStart = createTime[0].format("yyyy-MM-dd HH:mm:ss")
        let createTimeEnd = createTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.form.createTimeStart = createTimeStart
        this.form.createTimeEnd = createTimeEnd
      } else {
        this.form.createTimeStart = ""
        this.form.createTimeEnd = ""
      }

      if (this.form.firstTimeCondition) {
        let firstTime = this.form.firstTimeCondition
        let firstTimeStart = firstTime[0].format("yyyy-MM-dd HH:mm:ss")
        let firstTimeEnd = firstTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.form.firstTimeStart = firstTimeStart
        this.form.firstTimeEnd = firstTimeEnd
      } else {
        this.form.firstTimeStart = ""
        this.form.firstTimeEnd = ""
      }

      if (this.form.collectionTimeCondition) {
        let collectionTime = this.form.collectionTimeCondition
        let collectionTimeStart = collectionTime[0].format("yyyy-MM-dd HH:mm:ss")
        let collectionTimeEnd = collectionTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.form.collectionTimeStart = collectionTimeStart
        this.form.collectionTimeEnd = collectionTimeEnd
      } else {
        this.form.collectionTimeStart = ""
        this.form.collectionTimeEnd = ""
      }

      if (this.form.wakeTimeCondition) {
        let wakeTime = this.form.wakeTimeCondition
        let wakeTimeStart = wakeTime[0].format("yyyy-MM-dd HH:mm:ss")
        let wakeTimeEnd = wakeTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.form.wakeTimeStart = wakeTimeStart
        this.form.wakeTimeEnd = wakeTimeEnd
      } else {
        this.form.wakeTimeStart = ""
        this.form.wakeTimeEnd = ""
      }
      if (this.form.backStoreTimeCondition) {
        let backStoreTime = this.form.backStoreTimeCondition
        let backStoreTimeStart = backStoreTime[0].format("yyyy-MM-dd HH:mm:ss")
        let backStoreTimeEnd = backStoreTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.form.backStoreTimeStart = backStoreTimeStart
        this.form.backStoreTimeEnd = backStoreTimeEnd
      }


    },
    /**
     * 搜索
     */
    doSearch() {
      if (this.opporTypeRadios === '8' || this.opporTypeRadios === '9')  {
          const now = new Date();
          const end = now.getTime() + (2 * 86400000)
          this.form.backStoreTimeStart = now.format("yyyy-MM-dd HH:mm:ss")
          this.form.backStoreTimeEnd = new Date(end).format("yyyy-MM-dd HH:mm:ss")
      }else if (this.opporTypeRadios === '10' || this.opporTypeRadios === '11') {

      } else {
          this.$set(this.form, 'type', this.opporTypeRadios)
      }
      this.dueConditionTime()
      this.form['e'] = this.e
      this.$emit('doSearch', this.form)
    },
    getAllMediator: function () {
      api.allMediators().then(res => {
        if (res.success) {
          this.mediatorList = res.data
        }
      })
    },
  },
  mounted() {
    this.getAllMediator()
    this.getAreaList()
  }
}
</script>

<style scoped>

.block {
  display: inline;
}

.condition-input {
  width: 122px;
  height: 32px;
  border-radius: 4px;
}

.condition-select {
  width: 122px;
  height: 32px;
  border-radius: 4px;
}

.button-container {
  text-align: center;
}

.transition-box {
  transition: ease 0.5s;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 20px;
}

/deep/ .el-form-item {
  margin-bottom: 0;
  vertical-align: middle;
}

/deep/ .el-select__tags {
  max-width: initial !important;
}

</style>
